@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&display=swap');

@font-face {
  font-family: 'Toyota Display';
  src: url(../assets/fonts/ToyotaDisplay-Bold.woff) format("woff");
  font-weight: bold;
}

@font-face {
  font-family: 'Toyota Display';
  src: url(../assets/fonts/ToyotaDisplay.woff) format("woff");
  font-weight: normal;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../assets/fonts/ToyotaType-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../assets/fonts/ToyotaType-RegularIt.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../assets/fonts/ToyotaType-Semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: '++9';
  src: url(../assets/fonts/ToyotaType-SemiboldIt.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}