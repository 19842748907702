.system-notice {
  position: fixed;
  top: 100px;
  right: 130px;

  .system-notice-notice {
    margin-bottom: 8px;
    padding: 15px 27px;
    width: 280px;
    border-radius: 8px;
    background-color: #F0F0F0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;

    .system-notice-success {
      display: grid;
      grid-template-columns: min-content auto;
      column-gap: 11px;
      align-items: center;
      color: #4CAF50;
    }
  }
}
