.tsm-option-list {
  margin-top: -10px;
  padding: 7px 0 9px;

  border: 1px solid #CECECE;
  border-radius: 0 0 5px 5px;
  box-shadow: none;
  box-sizing: border-box;

  .ant-select-item {
    padding: 7px 20px;
    min-height: min-content;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;

  }
}

.tcm-multiple-option-list {
  background: #F0F0F0;
  border: 1px solid #CECECE;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;

  .ant-select-item-option-selected {
    background-color: transparent;
    font-weight: normal;
  }
}
