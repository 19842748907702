.tcm-date-picker {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;

  .ant-picker-header {
    border-bottom-color: #CECECE;;
  }

  .ant-picker-header-view {
    line-height: 50px;
    font-weight: 400;
  }

  thead {
    display: none;
  }

  .ant-picker-panel-container {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
  .ant-picker-date-panel {
    width: 340px;
    box-sizing: border-box;
  }

  .ant-picker-content {
    width: 100%;
  }

  .ant-picker-cell{
    
      &:not(.ant-picker-cell-in-view)  {
      color: rgba(0, 0, 0, 0.65);
      background: #eeeeeeee;
    }

      &.ant-picker-cell-disabled {
        color: #CECECE;
        
        &::before {
          background-color: transparent;
        }
      }
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #e50000;
  }
}
