@import '~antd/dist/antd.less';
@import '~@ant-design/compatible/assets/index.css';
@import './index.css';

/* Fonts */
@import './fonts';

/* TSM */
@import './tsmOptionList';
@import './tcmDatePicker';
@import './systemNotice';
